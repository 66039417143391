import { GET_REPORTS_FAILED } from "../constants/reports-types";
import { serverUrl, drsUrl, HTTP_STATUS } from "../config/config";
import axios from "axios";
import pushMessage  from "../components/common/pushMessage"
export const aepUser = axios.create({
  baseURL: process.env.REACT_APP_USER_SERVICE_API_URL,
  validateStatus: (status) => {
    if (status === 401) {
      console.log("Status is 401 Unauthorized");
      return false;
    }
    console.log("Status is 200 OK ");
    return true;
  },
  headers: {
    "Content-Type": "application/json",
  },
});

export const exportTable = async (endpoint, dataSet, timezone, fileName) => {
  dataSet["timezone"] = timezone;
  debugger;
  const response = await aepUser.post(
    //"http://localhost:8086/drs/download/"+endpoint,
    process.env.REACT_APP_DRS_SCHEDULE_API_URL + `/drs/download/${endpoint}`,
    dataSet,
    {
      responseType: "blob",
    }
  );
  if (response.status === 200) download(fileName, response.data);
};

export const download = (fileName, data) => {
  let url = window.URL.createObjectURL(new Blob([data]));
  let link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const exportDataTable = async (dataSet, fileName, exportViewColumns) => {
  const timezone = localStorage.timezone
  const data = { dataSet, timezone, exportViewColumns }
  const response = await aepUser.post(
    //"http://localhost:8086/drs/download/reports",
    process.env.REACT_APP_DRS_SCHEDULE_API_URL + `/drs/download/reports`,
     data, {
      responseType: "blob"
  });
  if (response.status === 200) download(fileName, response.data)
  else pushMessage("error", "Unable to export table");
}


