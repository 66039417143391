import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import "./reports.css";
import moment from "moment-timezone";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});

class ReportsTable extends React.Component {
  state = {
    total_count: "",
    currentPage: 1,
    pageSize: 10,
    upperPageBound: 7,
    lowerPageBound: 0,
    prevBtn: "disabled",
    nextBtn: "",
    pageBound: 7,
    reportsTableData: [],
  };

  componentWillReceiveProps = (newProps) => {
    const { reportDetails } = newProps;
    this.setState({
      total_count: reportDetails?.count,
      reportsTableData: reportDetails?.dataSet,
    });
  };

  handleClick = (event) => {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid,
    });
    // this.props.dispatch(getReports(listid, this.state.pageSize, {}))
    this.props.modifyDataAndDispatch(listid, this.state.pageSize);
    this.setPrevAndNextClass(listid);
  };

  setPrevAndNextClass = (listid) => {
    let totalPage = Math.ceil(this.state.total_count / this.state.pageSize);
    this.setState({ nextBtn: "disabled" });
    this.setState({ prevBtn: "disabled" });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ prevBtn: "" });
    } else if (listid === 1 && totalPage > 1) {
      this.setState({ nextBtn: "" });
    } else if (totalPage > 1) {
      this.setState({ nextBtn: "" });
      this.setState({ prevBtn: "" });
    }
  };

  incrementClick = () => {
    this.setState({
      upperPageBound: this.state.upperPageBound + this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
    });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    // this.props.dispatch(getReports(listid, this.state.pageSize, {}))
    this.props.modifyDataAndDispatch(listid, this.state.pageSize);
    this.setPrevAndNextClass(listid);
  };

  decrementClick = () => {
    this.setState({
      upperPageBound: this.state.upperPageBound - this.state.pageBound,
    });
    this.setState({
      lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
    });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    // this.props.dispatch(getReports(listid, this.state.pageSize, {}))
    this.props.modifyDataAndDispatch(listid, this.state.pageSize);
    this.setPrevAndNextClass(listid);
  };

  prevClick = () => {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({
        upperPageBound: this.state.upperPageBound - this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound - this.state.pageBound,
      });
    }

    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    // this.props.dispatch(getReports(listid, this.state.pageSize, {}))
    this.props.modifyDataAndDispatch(listid, this.state.pageSize);
    this.setPrevAndNextClass(listid);
  };

  nextClick = () => {
    if (this.state.currentPage + 1 > this.state.upperPageBound) {
      this.setState({
        upperPageBound: this.state.upperPageBound + this.state.pageBound,
      });
      this.setState({
        lowerPageBound: this.state.lowerPageBound + this.state.pageBound,
      });
    }
    let listid = this.state.currentPage + 1;
    this.setPrevAndNextClass(listid);
    this.setState({ currentPage: listid });
    // this.props.dispatch(getReports(listid, this.state.pageSize, {}))
    this.props.modifyDataAndDispatch(listid, this.state.pageSize);
  };

  render() {
    const { noData } = this.props;
    const {
      pageSize,
      currentPage,
      upperPageBound,
      lowerPageBound,
      prevBtn,
      nextBtn,
      reportsTableData,
    } = this.state;

    const pageNumbers = [];

    // Pagination
    for (let i = 1; i <= Math.ceil(this.state.total_count / pageSize); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map((number) => {
      let name = currentPage === number ? "active" : "";
      if (number < upperPageBound + 1 && number > lowerPageBound) {
        return (
          <li key={number} id={number} className={name}>
            <a
              id={number}
              onClick={this.handleClick}
              style={{ cursor: "pointer" }}
            >
              {number}
            </a>
          </li>
        );
      }
    });

    let pageIncrementBtn =
      pageNumbers.length > upperPageBound ? (
        <li className="">
          <a style={{ cursor: "pointer" }} onClick={this.incrementClick}>
            {" "}
            &#187;&#187;{" "}
          </a>
        </li>
      ) : null;
    let pageDecrementBtn =
      lowerPageBound >= 1 ? (
        <li className="">
          <a onClick={this.decrementClick} style={{ cursor: "pointer" }}>
            {" "}
            &#171;&#171;
          </a>
        </li>
      ) : null;
    let renderPrevBtn = null;
    let renderNextBtn = null;
    let totalPage = Math.ceil(this.state.total_count / this.state.pageSize);
    if (totalPage <= 1) {
      renderPrevBtn = (
        <li className="disabled">
          <span id="btnPrev"> Prev </span>
        </li>
      );
      renderNextBtn = (
        <li className="disabled">
          <span id="btnNext"> Next </span>
        </li>
      );
    } else {
      renderPrevBtn =
        prevBtn === "disabled" ? (
          <li className={prevBtn}>
            <span id="btnPrev"> Prev </span>
          </li>
        ) : (
            <li className={prevBtn}>
              <a
                id="btnPrev"
                onClick={this.prevClick}
                style={{ cursor: "pointer" }}
              >
                {" "}
              Prev{" "}
              </a>
            </li>
          );
      renderNextBtn =
        nextBtn === "disabled" ? (
          <li className={nextBtn}>
            <span id="btnNext"> Next </span>
          </li>
        ) : (
            <li className={nextBtn}>
              <a
                id="btnNext"
                onClick={this.nextClick}
                style={{ cursor: "pointer" }}
              >
                {" "}
              Next{" "}
              </a>
            </li>
          );
    }

    return (
      <div className="reports-table-div">
        {!!reportsTableData && reportsTableData.length > 0 && (
          <>
            <h4>Filter Result</h4>
            <Paper style={{ overflowX: "auto" }}>
              <Table aria-label="simple table">
                <TableHead className="reports-table-header">
                  <TableRow>
                    <TableCell>Handle</TableCell>
                    <TableCell>Scheduler</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Region</TableCell>
                    <TableCell>User Type</TableCell>
                    <TableCell>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Demo&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </TableCell>
                    <TableCell>Demo Vertical</TableCell>
                    <TableCell>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Resource&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </TableCell>
                    <TableCell>Start Date/Time</TableCell>
                    <TableCell>End Date/Time</TableCell>
                    <TableCell>Schedule Month</TableCell>
                    <TableCell>Schedule Hours</TableCell>
                    <TableCell>Demo Purpose</TableCell>
                    <TableCell>Purpose Name</TableCell>
                    <TableCell>Opportunity Number</TableCell>
                    <TableCell>Company</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!!reportsTableData &&
                    reportsTableData.length > 0 &&
                    reportsTableData.map((detail, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{detail.createdBy.userName}</TableCell>
                          <TableCell>
                            {detail.createdBy.firstName}{" "}
                            {detail.createdBy.lastName}
                          </TableCell>
                          <TableCell>
                            {!!detail.createdBy ? detail.createdBy.email : ""}
                          </TableCell>
                          <TableCell>{detail.createdBy.region.name}</TableCell>
                          <TableCell>{detail.userType}</TableCell>
                          <TableCell>{detail.demo.title}</TableCell>
                          <TableCell>{detail.vertical}</TableCell>
                          <TableCell>{detail.resource.name}</TableCell>
                          <TableCell>
                            {moment
                              .tz(detail.startTime, localStorage.timezone)
                              .format("Do MMM, YYYY hh:mm a")}
                          </TableCell>
                          <TableCell>
                            {moment
                              .tz(detail.endTime, localStorage.timezone)
                              .format("Do MMM, YYYY hh:mm a")}
                          </TableCell>
                          <TableCell>{detail.scheduleMonth}</TableCell>
                          <TableCell>{detail.scheduleDurInMin}</TableCell>
                          <TableCell>{detail.demoPurpose.purpose}</TableCell>
                          <TableCell>{detail.purposeName}</TableCell>
                          <TableCell>{detail.opportunityNumber}</TableCell>
                          <TableCell>
                            {!!detail.createdBy
                              ? detail.createdBy.company.company
                              : ""}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Paper>

            <div className="pagination">
              <ul>
                {renderPrevBtn}
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                {renderNextBtn}
              </ul>
            </div>
          </>
        )}
        <>
          {noData === true &&
            (typeof reportsTableData == "undefined" ||
              reportsTableData == null) && (
              <>
                <h4>No Matching Result For Above Criteria</h4>
              </>
            )}
        </>
      </div>
    );
  }
}

ReportsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return (
    {
      reportDetails: state.reports.reportDetails,
      noData: state.reports.noData,
    });
}


export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(ReportsTable))
);
