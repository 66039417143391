import { act } from "react-dom/test-utils";
import {
  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT
} from "../constants/auth-types";

export const initialState = {
  loginDetails: {},
  regions: [],
  authError: null,
  isLoggedIn: false
};

const handlers = {
  [LOGIN_INIT]: (state) => ({
    ...state,
  }),
  [LOGIN_SUCCESS]: (state, action) => {

    console.log(action)
    if (action.response?.message) {
      return {
        ...state,
        authError: "Invalid Username or password!",
      };
    } else {
      return {
        ...state,
        isLoggedIn: true
      };
    }
  },
  [LOGIN_FAILED]: (state) => {
    return {
      ...state,
    };
  },
  [LOGOUT]: (state) => {
    return {
      isLoggedIn: false
    };
  }
};
export default function authReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...state, ...handler(state, action) };
}
