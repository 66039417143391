import React, { Component } from "react";
import {
  Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import history from "../../utils/history";
import ReportsPage from "../../earp_pages/reports/reports-page";
import DemoCount from "../../earp_pages/demo-count/DemoCount";
import DemoHours from "../../earp_pages/demo-count/DemoHours";
import AssociateTop5 from "../../earp_pages/AssociateTop5";
import PartnerTop5 from "../../earp_pages/PartnerTop5";
import Login from "../../components/login";
import { loginAPI } from '../../api/auth-api';
import { login } from "../../actions";
import { connect } from "react-redux";


class App extends Component {

  state = {
    shouldRenderApp: false
  }

  componentDidMount() {
    const aepHeader = localStorage.getItem("aep-header");
    if (aepHeader) {
      // loginAPI(aepHeader)
      //   .then(() => history.push('/demo-usage'))
      //   .catch((err) => history.push('/login'))
      this.props.login(null, null, aepHeader);
    }
    else history.push('/login')
    this.setState({ shouldRenderApp: true })
  }

  render() {
    return (
      this.state.shouldRenderApp &&
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route exact path="/demo-usage" component={ReportsPage} />
        <Route exact path="/demo-count" component={DemoCount} />
        <Route exact path="/associate-top5" component={AssociateTop5} />
        <Route exact path="/partner-top5" component={PartnerTop5} />
        <Route exact path="/demo-hours" component={DemoHours} />
      </Switch>
    );
  }
}


export default connect(null, { login })(App);
