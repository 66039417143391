import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { createStore, applyMiddleware } from "redux";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducer from "./reducers";
import * as serviceWorker from "./serviceWorker";
import Grid from "@material-ui/core/Grid";
import history from './utils/history'
import App from "../src/components/app/app";
import SideNav from "../src/components/side-nav/side-nav";

const store = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <SideNav />
        </Grid>
        <Grid item xs={10}>
          <App />
        </Grid>
      </Grid>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
