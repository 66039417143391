import {
  GET_DEMO_NAMES_INIT,
  GET_DEMO_NAMES_SUCCESS,
  GET_DEMO_NAMES_FAILED,
  GET_USER_NAMES_INIT,
  GET_USER_NAMES_SUCCESS,
  GET_USER_NAMES_FAILED,
  GET_DEMO_PURPOSES_INIT,
  GET_DEMO_PURPOSES_SUCCESS,
  GET_DEMO_PURPOSES_FAILED,
  GET_REPORTS_INIT,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAILED,
  GET_COMPANY_NAMES_SUCCESS,
  GET_COMPANY_NAMES_INIT,
  GET_COMPANY_NAMES_FAILED,
  GET_DEMO_COUNT_INIT,
  GET_DEMO_COUNT_SUCCESS,
  GET_DEMO_COUNT_FAILED,
  GET_TOP_5_INIT,
  GET_PARTNER_TOP_5_SUCCESS,
  GET_ASSOCIATE_TOP_5_SUCCESS,
  GET_TOP_5_FAILED,
  GET_DEMO_HOURS_INIT,
  GET_DEMO_HOURS_SUCCESS,
  GET_DEMO_HOURS_FAILED
} from "../constants/reports-types";

export const initialState = {
  demoNames: {},
  demoPurposes: [],
  reportDetails: {},
  userNames: {},
  companyNames: [],
  demoCount: {},
  top5: {}
};

const handlers = {
  [GET_DEMO_NAMES_INIT]: (state) => ({
    ...state,
  }),
  [GET_DEMO_NAMES_SUCCESS]: (state, action) => {
    return {
      ...state,
      demoNames: action.response,
    };
  },
  [GET_DEMO_NAMES_FAILED]: (state) => {
    return {
      ...state,
    };
  },

  [GET_COMPANY_NAMES_INIT]: (state) => ({
    ...state,
  }),

  [GET_COMPANY_NAMES_SUCCESS]: (state, action) => {
    return {
      ...state,
      companyNames: action.response,
    };
  },

  [GET_COMPANY_NAMES_FAILED]: (state) => {
    return {
      ...state,
    };
  },

  [GET_USER_NAMES_INIT]: (state) => ({
    ...state,
  }),
  [GET_USER_NAMES_SUCCESS]: (state, action) => {
    return {
      ...state,
      userNames: action.response,
    };
  },
  [GET_USER_NAMES_FAILED]: (state) => {
    return {
      ...state,
    };
  },

  [GET_DEMO_PURPOSES_INIT]: (state) => ({
    ...state,
  }),
  [GET_DEMO_PURPOSES_SUCCESS]: (state, action) => {
    return {
      ...state,
      demoPurposes: action.response,
    };
  },
  [GET_DEMO_PURPOSES_FAILED]: (state) => {
    return {
      ...state,
    };
  },
  [GET_REPORTS_INIT]: (state) => ({
    ...state,
  }),
  [GET_REPORTS_SUCCESS]: (state, action) => {
    //console.log("action.response + -", action.response);
    return {
      ...state,
      reportDetails: action.response,
      noData: !action.response.count,
    };
  },
  [GET_REPORTS_FAILED]: (state) => {
    return {
      ...state,
    };
  },
  [GET_DEMO_COUNT_INIT]: (state) => ({
    ...state,
  }),
  [GET_DEMO_COUNT_SUCCESS]: (state, action) => {
    return {
      ...state,
      demoCount: action.response || {},
    };
  },
  [GET_DEMO_COUNT_FAILED]: (state) => {
    return {
      ...state,
    };
  },
  [GET_DEMO_HOURS_INIT]: (state) => ({
    ...state,
  }),
  [GET_DEMO_HOURS_SUCCESS]: (state, action) => {
    return {
      ...state,
      demoHours: action.response || {},
    };
  },
  [GET_DEMO_HOURS_FAILED]: (state) => {
    return {
      ...state,
    };
  },
  [GET_TOP_5_INIT]: (state) => ({
    ...state,
  }),
  
  [GET_PARTNER_TOP_5_SUCCESS]: (state, action) => {
    return {
      ...state,
      partnerTop5: action.response || {},
    };
  },
  
  [GET_ASSOCIATE_TOP_5_SUCCESS]: (state, action) => {
    return {
      ...state,
      assoiateTop5: action.response || {},
    };
  },

  [GET_TOP_5_FAILED]: (state) => {
    return {
      ...state,
    };
  }
};
export default function reportsReducer(state = initialState, action) {
  const handler = handlers[action.type];
  if (!handler) return state;
  return { ...state, ...handler(state, action) };
}
