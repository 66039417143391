import history from "../utils/history";
import pushMessage from "../components/common/pushMessage";

export function loginAPI(aepHeader, username, password) {
  let body = {
    email: username,
    password: password,
  };
  if (aepHeader) {
    // ${serverUrl} http://localhost:7073
    console.log(
      "process.env.REACT_APP_API_BASE_URL " + process.env.REACT_APP_API_BASE_URL
    );
    return fetch(
      process.env.REACT_APP_API_BASE_URL + `/userTemplateService/validate/configDetails`,
      //`http://localhost:7073/userTemplateService/validate/configDetails`,
      {
        method: "GET",
        headers: {
          "aep-header": aepHeader,
        },
      }
    ).then(() => history.push("/demo-usage"));
  } else {
    // ${serverUrl} http://localhost:7073/
    console.log(
      "process.env.REACT_APP_API_BASE_URL " + process.env.REACT_APP_API_BASE_URL
    );
    return fetch(
       process.env.REACT_APP_API_BASE_URL + `/backendLoginService/configDetails/reportingUser`,
      //`http://localhost:7073/backendLoginService/configDetails/reportingUser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify(body),
      }
    )
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("aep-header", res.headers.get("aep-header"));
          history.push("/demo-usage");
          return res.json();
        } else if (res.status === 403)
          pushMessage("error", "Invalid Username or Password!");
      })
      .then((result) => {
        localStorage.setItem("timezone", result.user.timezone);
        localStorage.setItem("myRegions", JSON.stringify(result.regions));
        history.push("/demo-usage");
      })
      .catch((err) => err);
  }
}
