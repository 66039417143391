export const getColumnsForExport = (records) => {
    const demoType = records?.data?.length > 0 && records?.data[0].hasOwnProperty('demoCount') ? "demoCount" : "demoHours";
    const countType = demoType === "demoCount" ? "scheduleCount" : "hoursScheduled";
    const hardCodingSubject = {
        "id" : 1,
        "columnName": " ",
        "columnKey": "subject"
    }
    let dataSet = records?.columns?.map((item, key) => {
        return {
            "id" : key+2,
            "columnName":item,
            "columnKey": `${demoType}.${item}.${countType}`
        }
    })
    return dataSet &&  [hardCodingSubject, ...dataSet]
}

export const getDataSetForExport = (records) => {
    const demoType = records?.data?.length > 0 && records?.data[0].hasOwnProperty('demoCount') ? "demoCount" : "demoHours"
    const countType = demoType === "demoCount" ? "scheduleCount" : "hoursScheduled";
    const dataSet = records?.data?.map((item) => {
        return {
            "subject": item.subject,
            [demoType]:{
                ...item[demoType]?.reduce((acc, item) => {
                    acc[item.month] = {
                        [countType] : item[countType]
                    } 
                    return acc
                }, {})
            }

        }
    })
    return dataSet;
}