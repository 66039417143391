import { serverUrl, drsUrl } from "../config/config";
import { tempToken } from "../config/token";
import moment from "moment-timezone";

export function getDemoNamesAPI() {
  const token = localStorage.getItem("aep-header");
  //${serverUrl} http://localhost:7070
  return fetch(
    process.env.REACT_APP_API_BASE_URL + `/demoSolutionService/demos`,
    //`http://localhost:7070/demoSolutionService/demos`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "aep-header": token,
      },
    }
  )
    .then((res) => res.json())
    .catch((err) => err);
}

export function getUserNamesAPI() {
  const token = localStorage.getItem("aep-header");
  //${serverUrl} http://localhost:7073/
  return fetch(
    process.env.REACT_APP_API_BASE_URL + `/userTemplateService/users`,
    //`http://localhost:7073/userTemplateService/users`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "aep-header": token,
      },
    }
  )
    .then((res) => res.json())
    .catch((err) => err);
}

export const fetchCompaniesBySearchString = async ({ pageNumber = 0, pageSize = 100, searchString }) => {
  const token = localStorage.getItem("aep-header");
  return fetch(
    process.env.REACT_APP_API_BASE_URL + `/userTemplateService/companies/search?searchString=${searchString}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "aep-header": token,
      },
    }
  )
    .then((res) => res.json())
    .catch((err) => err);
}

export function getComanyNamesAPI() {
  const token = localStorage.getItem("aep-header");
  //${serverUrl} http://localhost:7073/
  return fetch(
    process.env.REACT_APP_API_BASE_URL + `/userTemplateService/companies/list`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "aep-header": token,
      },
    }
  )
    .then((res) => res.json())
    .catch((err) => err);
}

export function getDemoPurposesAPI() {
  const token = localStorage.getItem("aep-header");
  // ${serverUrl} http://localhost:7071/
  return fetch(
    process.env.REACT_APP_API_BASE_URL + `/scheduleMaintenanceService/purposes`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "aep-header": token,
      },
    }
  )
    .then((res) => res.json())
    .catch((err) => err);
}

export function getDemoCountAPI(startTime, endTime, utcOffset) {
  const token = localStorage.getItem("aep-header");
  return fetch(
    process.env.REACT_APP_DRS_SCHEDULE_API_URL + `/drs/monthWiseDemos/count?startDate=${startTime}&endDate=${endTime}&interval=${utcOffset}`,
    //`http://localhost:8086/drs/monthWiseDemos/count?startDate=${startTime}&endDate=${endTime}&interval=${utcOffset}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "aep-header": token,
      },

    }
  )
    .then((res) => res.json())
    .catch((err) => err);
}

export function getDemoHoursAPI(startTime, endTime, utcOffset) {
  const token = localStorage.getItem("aep-header");
  return fetch(
    process.env.REACT_APP_DRS_SCHEDULE_API_URL + `/drs/monthWiseDemos/hours?startDate=${startTime}&endDate=${endTime}&interval=${utcOffset}`,
    //`http://localhost:8086/drs/monthWiseDemos/hours?startDate=${startTime}&endDate=${endTime}&interval=${utcOffset}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "aep-header": token,
      },

    }
  )
    .then((res) => res.json())
    .catch((err) => err);
}

export function getTop5API(startTime, endTime, type, utcOffset) {
  const token = localStorage.getItem("aep-header");
  return fetch(
    process.env.REACT_APP_DRS_SCHEDULE_API_URL + `/drs/monthWiseTrendingDemos/count/topN/${type}?startDate=${startTime}&endDate=${endTime}&interval=${utcOffset}`,
    //`http://localhost:8086/drs/monthWiseTrendingDemos/count/topN/${type}?startDate=${startTime}&endDate=${endTime}&interval=${utcOffset}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "aep-header": token,
      },

    }
  )
    .then((res) => res.json())
    .catch((err) => err);
}

// [11:34 AM] Chandrashekhar Kadam
// https://192.168.62.22/scheduleMaintenanceService/schedules?pageNumber=1&pageSize=10&filterSort={filter{"demo.title":"demoName", "demoPurpose.purpose":"purposeName","startTime":"",endTime=""}}
export function getReportsAPI(pageNumber, pageSize, filter) {
  const token = localStorage.getItem("aep-header");
  //http://localhost:8086
  let completeUrl =
    process.env.REACT_APP_DRS_SCHEDULE_API_URL +
    `/drs/schedules?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  //`http://localhost:8086/drs/schedules?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  let url = "";
  if (Object.keys(filter).length > 0) {
    url = `{"filter":{"includeStartAndEndDate":true,`;
    if (!!filter["demo.title"] && filter["demo.title"].length > 0) {
      filter["demo.title"].forEach((value, i) => {
        if (i == 0) {
          url = `${url}"demo.title":"${value}`;
        } else {
          // url = url + `\\\\` + value;
          url = `${url}\\\\${value}`;
        }
      });

      url = `${url}"`;
    }
    // demoPurpose.purpose
    if (!!filter["company"] && filter["company"].length > 0) {
      if (!!filter["demo.title"]) {
        url = `${url}, `;
      }
      filter["company"].forEach((value, i) => {
        if (i == 0) {
          url = `${url}"createdBy.company.company":"${value}`;
        } else {
          // url = url + `\\\\` + value;
          url = `${url}\\\\${value}`;
        }
      });
      url = `${url}"`;
    }
    //

    if (!!filter["user.email"] && filter["user.email"].length > 0) {
      if (!!filter["demo.title"] || !!filter["company"]) {
        url = `${url}, `;
      }
      filter["user.email"].forEach((value, i) => {
        if (i == 0) {
          url = `${url}"createdBy.email":"${value}`;
        } else {
          // url = url + `\\\\` + value;
          url = `${url}\\\\${value}`;
        }
      });

      url = `${url}"`;
    }

    if (!!filter["region"] && filter["region"].length > 0) {
      if (
        !!filter["demo.title"] ||
        !!filter["company"] ||
        !!filter["user.email"]
      ) {
        url = `${url}, `;
      }
      filter["region"].forEach((value, i) => {
        if (i == 0) {
          url = `${url}"createdBy.region.name":"${value}`;
        } else {
          // url = url + `\\\\` + value;
          url = `${url}\\\\${value}`;
        }
      });

      url = `${url}"`;
    }

    //if (!!filter['demoPurpose.purpose']) {
    //  if (!!filter['demo.title'] || !!filter['user.email']) {
    //   url = `${url}, `;
    //  }
    //  url = `${url}"demoPurpose.purpose":"${filter['demoPurpose.purpose']}"`;
    //}
    if (!!filter["startTime"]) {
      if (
        !!filter["demo.title"] ||
        !!filter["company"] ||
        !!filter["user.email"] ||
        !!filter["region"]
      ) {
        url = `${url}, `;
      }
      //filter["startTime"] = new Date(filter["startTime"]).toISOString();
      url = `${url}"startTime":"${filter["startTime"]}"`;
    }
    if (!!filter["endTime"]) {
      if (
        !!filter["demo.title"] ||
        !!filter["company"] ||
        !!filter["user.email"] ||
        !!filter["region"] ||
        !!filter["startTime"]
      ) {
        url = `${url}, `;
      }
      //filter["endTime"] = new Date(filter["endTime"]).toISOString();
      url = `${url}"endTime":"${filter["endTime"]}"`;
    }

    // if (!!filter['region']) {
    //   if (!!filter['demo.title'] || !!filter['demoPurpose.purpose'] || !!filter['startTime'] || !!filter['endTime']) {
    //     url = `${url}, `;
    //   }
    //   url = `${url}"region":"${filter['region']}"`;
    // }
    url = `${url}}}`;
    completeUrl = `${completeUrl}&filterSort=${encodeURIComponent(url)}`;
  }
  return fetch(completeUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "aep-header": token,
    },
  })
    .then((res) => res.json())
    .catch((err) => err);
}

// new export
export function getExportToExcel(filter) {
  console.log("getExportToExcel -- 2");
  debugger;
  const token = localStorage.getItem("aep-header");
  //let completeUrl = `http://localhost:8086/drs/schedules`;
  let completeUrl = process.env.REACT_APP_DRS_SCHEDULE_API_URL + `/drs/schedules`;
  let url = "";
  if (Object.keys(filter).length > 0) {
    url = `{"filter":{`;
    if (!!filter["demo.title"] && filter["demo.title"].length > 0) {
      filter["demo.title"].forEach((value, i) => {
        if (i == 0) {
          url = `${url}"demo.title":"${value}`;
        } else {
          // url = url + `\\\\` + value;
          url = `${url}\\\\${value}`;
        }
      });

      url = `${url}"`;
    }

    if (
      !!filter["demoPurpose.purpose"] &&
      filter["demoPurpose.purpose"].length > 0
    ) {
      if (!!filter["demo.title"]) {
        url = `${url}, `;
      }
      filter["demoPurpose.purpose"].forEach((value, i) => {
        if (i == 0) {
          url = `${url}"demoPurpose.purpose":"${value}`;
        } else {
          // url = url + `\\\\` + value;
          url = `${url}\\\\${value}`;
        }
      });

      url = `${url}"`;
    }

    if (!!filter["user.email"] && filter["user.email"].length > 0) {
      if (!!filter["demo.title"] || !!filter["demoPurpose.purpose"]) {
        url = `${url}, `;
      }
      filter["user.email"].forEach((value, i) => {
        if (i == 0) {
          url = `${url}"createdBy.email":"${value}`;
        } else {
          // url = url + `\\\\` + value;
          url = `${url}\\\\${value}`;
        }
      });

      url = `${url}"`;
    }

    if (!!filter["region"] && filter["region"].length > 0) {
      if (
        !!filter["demo.title"] ||
        !!filter["demoPurpose.purpose"] ||
        !!filter["user.email"]
      ) {
        url = `${url}, `;
      }
      filter["region"].forEach((value, i) => {
        if (i == 0) {
          url = `${url}"createdBy.region.name":"${value}`;
        } else {
          // url = url + `\\\\` + value;
          url = `${url}\\\\${value}`;
        }
      });

      url = `${url}"`;
    }

    //if (!!filter['demoPurpose.purpose']) {
    //  if (!!filter['demo.title'] || !!filter['user.email']) {
    //   url = `${url}, `;
    //  }
    //  url = `${url}"demoPurpose.purpose":"${filter['demoPurpose.purpose']}"`;
    //}
    if (!!filter["startTime"]) {
      if (
        !!filter["demo.title"] ||
        !!filter["demoPurpose.purpose"] ||
        !!filter["user.email"] ||
        !!filter["region"]
      ) {
        url = `${url}, `;
      }
      //filter["startTime"] = new Date(filter["startTime"]).toISOString();
      url = `${url}"startTime":"${filter["startTime"]}"`;
    }
    if (!!filter["endTime"]) {
      if (
        !!filter["demo.title"] ||
        !!filter["demoPurpose.purpose"] ||
        !!filter["user.email"] ||
        !!filter["region"] ||
        !!filter["startTime"]
      ) {
        url = `${url}, `;
      }
      //filter["endTime"] = new Date(filter["endTime"]).toISOString();
      url = `${url}"endTime":"${filter["endTime"]}"`;
    }

    // if (!!filter['region']) {
    //   if (!!filter['demo.title'] || !!filter['demoPurpose.purpose'] || !!filter['startTime'] || !!filter['endTime']) {
    //     url = `${url}, `;
    //   }
    //   url = `${url}"region":"${filter['region']}"`;
    // }
    url = `${url}}}`;
    completeUrl = `${completeUrl}?filterSort=${encodeURIComponent(url)}`;
  }
  return fetch(completeUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      "aep-header": token,
    },
  })
    .then((res) => res.json())
    .catch((err) => err);
}
