export const GET_DEMO_NAMES_INIT = "GET_DEMO_NAMES_INIT";
export const GET_DEMO_NAMES_SUCCESS = "GET_DEMO_NAMES_SUCCESS";
export const GET_DEMO_NAMES_FAILED = "GET_DEMO_NAMES_FAILED";
export const GET_USER_NAMES_INIT = "GET_USER_NAMES_INIT";
export const GET_USER_NAMES_SUCCESS = "GET_USER_NAMES_SUCCESS";
export const GET_USER_NAMES_FAILED = "GET_USER_NAMES_FAILED";
export const GET_DEMO_PURPOSES_INIT = "GET_DEMO_PURPOSES_INIT";
export const GET_DEMO_PURPOSES_SUCCESS = "GET_DEMO_PURPOSES_SUCCESS";
export const GET_DEMO_PURPOSES_FAILED = "GET_DEMO_PURPOSES_FAILED";
export const GET_REPORTS_INIT = "GET_REPORTS_INIT";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAILED = "GET_REPORTS_FAILED";
export const GET_COMPANY_NAMES_SUCCESS = "GET_COMPANY_NAMES_SUCCESS";
export const GET_COMPANY_NAMES_INIT = "GET_COMPANY_NAMES_INIT";
export const GET_COMPANY_NAMES_FAILED = "GET_COMPANY_NAMES_FAILED";
export const GET_DEMO_COUNT_INIT = "GET_DEMO_COUNT_INIT";
export const GET_DEMO_COUNT_SUCCESS = "GET_DEMO_COUNT_SUCCESS";
export const GET_DEMO_COUNT_FAILED = "GET_DEMO_COUNT_FAILED";
export const GET_TOP_5_INIT = "GET_TOP_5_INIT";
export const GET_TOP_5_SUCCESS = "GET_TOP_5_SUCCESS"; 
export const GET_PARTNER_TOP_5_SUCCESS = "GET_PARTNER_TOP_5_SUCCESS";
export const GET_ASSOCIATE_TOP_5_SUCCESS = "GET_ASSOCIATE_TOP_5_SUCCESS";
export const GET_TOP_5_FAILED = "GET_TOP_5_FAILED";
export const GET_DEMO_HOURS_INIT = "GET_DEMO_HOURS_INIT";
export const GET_DEMO_HOURS_SUCCESS = "GET_DEMO_HOURS_SUCCESS";
export const GET_DEMO_HOURS_FAILED = "GET_DEMO_HOURS_FAILED";