import moment from "moment-timezone";

import {
  getDemoNamesAPI,
  getUserNamesAPI,
  getDemoPurposesAPI,
  getReportsAPI,
  getExportToExcel,
  getComanyNamesAPI,
  getDemoCountAPI,
  getTop5API,
  getDemoHoursAPI
} from "../api/reports-api";

import {
  GET_DEMO_NAMES_INIT,
  GET_DEMO_NAMES_SUCCESS,
  GET_DEMO_NAMES_FAILED,
  GET_USER_NAMES_INIT,
  GET_USER_NAMES_SUCCESS,
  GET_USER_NAMES_FAILED,
  GET_DEMO_PURPOSES_INIT,
  GET_DEMO_PURPOSES_SUCCESS,
  GET_DEMO_PURPOSES_FAILED,
  GET_REPORTS_INIT,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAILED,
  GET_COMPANY_NAMES_INIT,
  GET_COMPANY_NAMES_SUCCESS,
  GET_COMPANY_NAMES_FAILED,
  GET_DEMO_COUNT_INIT,
  GET_DEMO_COUNT_SUCCESS,
  GET_DEMO_COUNT_FAILED,
  GET_TOP_5_INIT,
  GET_PARTNER_TOP_5_SUCCESS,
  GET_ASSOCIATE_TOP_5_SUCCESS,
  GET_TOP_5_FAILED,
  GET_DEMO_HOURS_INIT,
  GET_DEMO_HOURS_SUCCESS,
  GET_DEMO_HOURS_FAILED
} from "../constants/reports-types";

import { exportTable } from "./utility-actions";

export function getDemoNames() {
  return (dispatch) => {
    dispatch({ type: GET_DEMO_NAMES_INIT });
    getDemoNamesAPI()
      .then((response) => dispatch({ type: GET_DEMO_NAMES_SUCCESS, response }))
      .catch((error) => dispatch({ type: GET_DEMO_NAMES_FAILED, error }));
  };
}

export function getUserNames() {
  return (dispatch) => {
    dispatch({ type: GET_USER_NAMES_INIT });
    getUserNamesAPI()
      .then((response) => dispatch({ type: GET_USER_NAMES_SUCCESS, response }))
      .catch((error) => dispatch({ type: GET_USER_NAMES_FAILED, error }));
  };
}

export function getComanyNames() {
  return (dispatch) => {
    dispatch({ type: GET_COMPANY_NAMES_INIT });
    getComanyNamesAPI()
      .then((response) => {
        dispatch({ type: GET_COMPANY_NAMES_SUCCESS, response });
      })
      .catch((error) => dispatch({ type: GET_COMPANY_NAMES_FAILED, error }));
  };
}

export function getDemoPurposes() {
  return (dispatch) => {
    dispatch({ type: GET_DEMO_PURPOSES_INIT });
    getDemoPurposesAPI()
      .then((response) =>
        dispatch({ type: GET_DEMO_PURPOSES_SUCCESS, response })
      )
      .catch((error) => dispatch({ type: GET_DEMO_PURPOSES_FAILED, error }));
  };
}

export function getReports(pageNumber, pageSize, filter) {
  return (dispatch) => {
    dispatch({ type: GET_REPORTS_INIT });
    getReportsAPI(pageNumber, pageSize, filter)
      .then((response) => dispatch({ type: GET_REPORTS_SUCCESS, response }))
      .catch((error) => dispatch({ type: GET_REPORTS_FAILED, error }));
  };
}

export function getDemoCount(startDate, endDate) {
  return (dispatch) => {
    dispatch({ type: GET_DEMO_COUNT_INIT });
    const utcOffset = moment().tz(localStorage.timezone).utcOffset()
    console.log("utcOffset ------------- ", utcOffset);
    getDemoCountAPI(startDate, endDate, utcOffset)
      .then((response) => dispatch({ type: GET_DEMO_COUNT_SUCCESS, response }))
      .catch((error) => dispatch({ type: GET_DEMO_COUNT_FAILED, error }));
  };
}

export function getDemoHours(startDate, endDate) {
  return (dispatch) => {
    dispatch({ type: GET_DEMO_HOURS_INIT });
    const utcOffset = moment().tz(localStorage.timezone).utcOffset()
    console.log("utcOffset ------------- ", utcOffset);
    getDemoHoursAPI(startDate, endDate, utcOffset)
      .then((response) => dispatch({ type: GET_DEMO_HOURS_SUCCESS, response }))
      .catch((error) => dispatch({ type: GET_DEMO_HOURS_FAILED, error }));
  };
}

export function getTop5(startDate, endDate, type) {
  return (dispatch) => {
    dispatch({ type: GET_TOP_5_INIT });
    const utcOffset = moment().tz(localStorage.timezone).utcOffset()
    getTop5API(startDate, endDate, type, utcOffset)
      .then((response) => { 
       if(type==='Partner')
          dispatch({ type: GET_PARTNER_TOP_5_SUCCESS, response })
      if(type==='Associate')
          dispatch({ type: GET_ASSOCIATE_TOP_5_SUCCESS, response })
    })
      .catch((error) => dispatch({ type: GET_TOP_5_FAILED, error }));
  };
}

// getExportToExcel
export function getExport(filter) {

  return (dispatch) => {
    dispatch({ type: GET_REPORTS_INIT });
    getExportToExcel(filter)
      .then((schedules) =>
        exportTable(
          "scheduleDemos",
          schedules,
          localStorage.timezone,
          "Scheduled Demos.xlsx"
        )
      )
      .catch((error) => dispatch({ type: GET_REPORTS_FAILED, error }));
  };
}

