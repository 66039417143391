import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Line, Column } from '@ant-design/charts';
import moment from "moment-timezone";
import "rsuite/dist/styles/rsuite-default.css";
import Grid from "@material-ui/core/Grid";
import { getTop5 } from "../actions";
import { getColumnsForExport, getDataSetForExport } from "../components/common/exportData";
import { exportDataTable } from "../actions/utility-actions";
import pushMessage  from "../components/common/pushMessage";
class PartnerTop5 extends React.Component {

    state = {
        dateValue: [],
        startDate: undefined,
        endDate: undefined,
        graphType: "LINEGRAPH"
    };

    getColumns = () => {
        const { top5 } = this.props;
        const columns = top5?.columns?.map((item) => {
            return { field: item, headerName: item, type: 'number' };
        })
        return columns ? [{ field: "type", headerName: " ", width :400}, ...columns] : [];
    };

    getRows = () => {
        const { top5 } = this.props;
        const rows = top5?.data?.map((item, index) => {
            return {
                id: index, type: item.subject, ...item.demoCount?.reduce((acc, item) => {
                    acc[item.month] = item.scheduleCount;
                    return acc
                }, {})
            }
        })
        return rows || [];
    }

    handleFilter = () => {
        const { dispatch } = this.props;
        const { startDate, endDate } = this.state;
        dispatch(getTop5(startDate, endDate, 'Partner'));
    };

    handleExport = () => {
        const { top5 } = this.props;
        const dataSet = getDataSetForExport(top5);
        const exportViewColumns = getColumnsForExport(top5);
        exportDataTable( dataSet, "Reports.xlsx", exportViewColumns)
                .then(() => {
                    pushMessage("success", "Report exported successfully")
                })
                .catch(() => {
                    pushMessage("error", "Unable to export report")
                })
        
    }

    handleGraphTypeChange = (graphType) => {
        this.setState({ graphType: graphType });
    }

    render() {
        var startOfMonth = require('date-fns/start_of_month');
        var startOfYear = require('date-fns/start_of_year');
        var endOfMonth = require('date-fns/end_of_month');
        var endOfYear = require('date-fns/end_of_year');
        var startOfDay = require('date-fns/start_of_day');
        var endOfDay = require('date-fns/end_of_day');

        const { graphType } = this.state;
        const { top5 } = this.props;

        var lineGraphConfig = {
            data: top5?.chart,
            autoFit: true,
            xField: 'month',
            xAxis: {
                label: {
                    autoRotate: false,
                    offset: 10,
                    style: {
                        fontSize: 11,
                    }
                }
            },
            yField: 'scheduleCount',
            seriesField: 'demoTitle',
            color: ['#004066', '#D59006', '#899E8B', '#482902', '#E5350B'],
            smooth: true,
            legend: {
                position: 'right'
            }
        };

        var barGraphConfig = {
            data: top5?.chart,
            autoFit: true,
            xField: 'month',
            xAxis: {
                label: {
                    autoRotate: false,
                    offset: 10,
                    style: {
                        fontSize: 11,
                    }
                }
            },
            yField: 'scheduleCount',
            color: ['#004066', '#D59006', '#899E8B', '#482902', '#E5350B'],
            seriesField: 'demoTitle',
            legend: {
                position: 'right'
            },
            isStack: true,
        };

        const DateRangePickerCustomToolbar = (props) => (

            <div className="field">
                <DateRangePicker
                    onChange={(value) => {
                        var startTimeStr1 = moment(value[0]).tz(localStorage.timezone, true).toISOString();
                        var endTimeStr1 = moment(endOfDay(value[1])).tz(localStorage.timezone, true).toISOString();
                        this.setState({ dateValue: value, startDate: startTimeStr1, endDate: endTimeStr1 });
                    }
                    }
                    value={this.state.dateValue}
                    ranges={[
                        {
                            label: 'Last Month',
                            value: [
                                startOfDay(startOfMonth(startOfMonth(new Date()) - 1)),
                                endOfDay(endOfMonth(startOfMonth(new Date()) - 1))
                            ]
                        },
                        {
                            label: 'Last 2 Month',
                            value: [
                                startOfDay( startOfMonth (startOfMonth( startOfMonth(new Date() ) - 1) - 1 ) ),
                                endOfDay(endOfMonth(startOfMonth(new Date()) - 1))
                            ]
                        },
                        {
                            label: 'Last 3 Month',
                            value: [
                                startOfDay( startOfMonth( startOfMonth (startOfMonth( startOfMonth(new Date() ) - 1) - 1 ) - 1) ),
                                endOfDay(endOfMonth(startOfMonth(new Date()) - 1))
                            ]
                        },
                        {
                            label: 'Last 6 Month',
                            value: [
                                startOfDay( 
                                    startOfMonth( 
                                        startOfMonth( 
                                            startOfMonth( 
                                                startOfMonth( 
                                                    startOfMonth (
                                                        startOfMonth( 
                                                                      startOfMonth(new Date() ) - 1
                                                        ) - 1 
                                                    ) - 1
                                                ) - 1
                                            ) - 1 
                                        ) - 1
                                    )
                                ),

                                endOfDay(endOfMonth(startOfMonth(new Date()) - 1))
                            ]
                        },
                        {
                            label: 'This Year',
                            value: [
                                startOfDay(startOfYear(new Date())),
                                endOfDay(new Date())
                            ]
                        },
                        {
                            label: 'Last Year',
                            value: [
                                startOfDay(startOfYear(startOfYear(new Date()) - 1)),
                                endOfDay(endOfYear(startOfYear(new Date()) - 1))
                            ]
                        },
                    ]}
                />
            </div>
        );

        return (
            <>
                <a href="/login" className="logout" title="Log Out" onClick={() => localStorage.setItem("aep-header", "")}>
                    <i className="fas fa-sign-out-alt">&nbsp;&nbsp;&nbsp;&nbsp;</i>
                </a>
                <br />
                <h3>Partner's Top 5</h3>

                <Grid style={{ marginTop: "1%" }} container spacing={2}>
                    <Grid item xs={3}>
                        <DateRangePickerCustomToolbar />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            className="button-style"
                            variant="contained"
                            onClick={() => this.handleFilter()}
                        >
                            Submit
                     </Button>
                    </Grid>
                </Grid>
                <br />
                <br />
                <ButtonGroup color="primary" size="small" aria-label="small outlined primary button group">
                    <Button value="lineGraph" onClick={() => this.handleGraphTypeChange("LINEGRAPH")}>Line Graph</Button>
                    <Button value="barGraph" onClick={() => this.handleGraphTypeChange("BARGRAPH")}>Bar Graph</Button>
                </ButtonGroup>

                <br />
                <br />
                <div style={{width: "70%"}}>
                    { graphType === "LINEGRAPH" ? this.props.top5?.chart!=undefined && <Line {...lineGraphConfig} height={350} /> : this.props.top5?.chart!=undefined && <Column {...barGraphConfig} height={350} />}
                </div>
                <br />
                <br />
                <br />
                <br />
                {this.props.top5 && <DataGrid id="Partner" rows={this.getRows()} columns={this.getColumns()} autoHeight={true} hideFooter={true} />}
                <div align="right" style={{marginTop:"330px"}}>
                    <Grid>
                        {top5?.chart && <Button
                                    className="button-style"
                                    variant="contained"
                                    onClick={() => this.handleExport()}
                                >
                                    Export
                            </Button>}
                    </Grid>
                </div>
                <br />
                <br />
            </>
        );
    }

}

const mapStateToProps = (state) => ({
    top5: state.reports.partnerTop5
});

export default withRouter(connect(mapStateToProps)(PartnerTop5));
