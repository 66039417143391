import React, { Component } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import history from "../../utils/history";
import "./side-nav.css";

const drawerWidth = 240;
const imagesPath = process.env.PUBLIC_URL + "/avaya_nav_logo.png";

const styles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "200px",
    },
    paperAnchorLeft: {
      width: "200px",
    },
  })
);

class SideNav extends Component {

  state = {
    selectedIndex: 0
  }

  handleNavigation = (url) => {
    history.push(url);
  };

  handleListItemClick = (event, index) => {
    this.setState({ selectedIndex: index })
  };

  render() {
    const classes = this.props;
    const { selectedIndex } = this.state;
    return (
      <div className={classes.root} style={{ backgroundColor: "#E7E7E7" }}>
        <Drawer
          className="drawer"
          variant="permanent"
          // classes={{
          //     paper: classes.drawerPaper,
          // }}
          anchor="left"
        >
          <div className={classes.toolbar} />
          <Divider />
          <div>
            <img src={imagesPath} />
            <p className="title">EAP Reporting &nbsp;</p>
          </div>
          {this.props.isLoggedIn &&
            <List component="div" disablePadding style={{ paddingTop: '30px' }}>
              <ListItem
                selected={selectedIndex === 0}
                onClick={(event) => {
                  this.handleListItemClick(event, 0)
                  history.push('/demo-usage')
                }}
                button>
                <ListItemText primary="Demo Usage" />
              </ListItem>
              <ListItem
                selected={selectedIndex === 1}
                onClick={(event) => {
                  this.handleListItemClick(event, 1)
                  history.push('/demo-count')
                }}
                button>
                <ListItemText primary="Demo Count" />
              </ListItem>

              <ListItem
                selected={selectedIndex === 2}
                onClick={(event) => {
                  this.handleListItemClick(event, 2)
                  history.push('/associate-top5')
                }}
                button>
                <ListItemText primary="Associate's Top 5" />
              </ListItem>
              <ListItem
                selected={selectedIndex === 3}
                onClick={(event) => {
                  this.handleListItemClick(event, 3)
                  history.push('/partner-top5')
                }}
                button>
                <ListItemText primary="Partner's Top 5" />
              </ListItem>
              <ListItem
                selected={selectedIndex === 4}
                onClick={(event) => {
                  this.handleListItemClick(event, 4)
                  history.push('/demo-hours')
                }}
                button>
                <ListItemText primary="Demo Hours" />
              </ListItem>
            </List>
          }
        </Drawer>
      </div>
    );
  }
}
SideNav.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return ({
    isLoggedIn: state.auth.isLoggedIn
  });
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(SideNav));
