import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateRangePicker } from "rsuite";
import dateFns from "date-fns";
import "rsuite/dist/styles/rsuite-default.css";
import debounce from 'lodash/debounce';
import moment from "moment-timezone";

// import SearchIcon from '@material-ui/icons/Search';
import {
  getDemoNames,
  getDemoPurposes,
  getReports,
  //login,
  getUserNames,
  getExport,
  //getComanyNames,
} from "../../actions";
import { fetchCompaniesBySearchString } from '../../api/reports-api'
import ReportsTable from "./reports-table";

const BootstrapInput = withStyles((theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      //fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});

class ReportsPage extends Component {

  constructor(props) {
    super(props);
    this.fetchCompanies = debounce(this.fetchCompanies, 800);
  }

  state = {
    demoName: "",
    demoPurpose: "",
    startDate: "",
    endDate: "",
    region: "",
    demoNameValues: null,
    userNameValues: null,
    demoNameOptions: [],
    regionValues: null,
    companyNameValues: [],
    purposeValues: null,
    dateValue: [],
    matchedCompanies: [],
    searchString: [],
    fetchingCompanies: false,
  };

  componentDidMount = () => {
    const { dispatch } = this.props;
    // dispatch(login("backend.superadmin@avaya.com", "AvayaDem0"));
    dispatch(getDemoNames());
    dispatch(getDemoPurposes());
    dispatch(getUserNames());
    //dispatch(getComanyNames());
  };

  handleDemoPurposeChange = (event) => {
    this.setState({
      demoPurpose: event.target.value,
    });
  };

  handleRegionChange = (event) => {
    this.setState({
      region: event.target.value,
    });
  };

  handleModifyDataAndDispatch = (pageNumber, pageSize) => {
    const { dispatch } = this.props;
    let filter = this.formFilterObject();
    dispatch(getReports(pageNumber, pageSize, filter));
  };

  formFilterObject = () => {
    const {
      startDate,
      endDate,
      demoNameValues,
      regionValues,
      userNameValues,
      companyNameValues,
      purposeValues,
    } = this.state;
    let filter = {};
    if (!!demoNameValues && demoNameValues.length > 0) {
      filter["demo.title"] = [];
      demoNameValues.forEach((demo) => {
        filter["demo.title"].push(demo.value);
      });
    }

    if (!!companyNameValues && companyNameValues.length > 0) {
      filter["company"] = [];
      companyNameValues.forEach((company) => {
        filter["company"].push(company.title);
      });
    }

    if (!!purposeValues && purposeValues.length > 0 && purposeValues != "ALL") {
      filter["demoPurpose.purpose"] = [];
      purposeValues.forEach((purpose) => {
        filter["demoPurpose.purpose"].push(purpose.value);
      });
    }

    if (!!userNameValues && userNameValues.length > 0) {
      filter["user.email"] = [];
      userNameValues.forEach((user) => {
        filter["user.email"].push(user.value);
      });
    }
    if (!!startDate && startDate.length > 0) {
      console.log("Start date --------------" + startDate);
      filter["startTime"] = startDate;
    }
    if (!!endDate && endDate.length > 0) {
      console.log("End date --------------" + endDate);
      filter["endTime"] = endDate;
    }
    if (!!regionValues && regionValues.length > 0) {
      filter["region"] = [];
      regionValues.forEach((region) => {
        filter["region"].push(region.value);
      });
    }
    console.log("+-----+----------- ", filter);
    return filter;
  };

  clearSearch = () => {
    this.setState({
      startDate: "",
      endDate: "",
      demoNameValues: "",
      regionValues: "",
      userNameValues: "",
      purposeValues: "",
      dateValue: [],
      companyNameValues: [],
    });


  };

  handleFilter = () => {
    const { dispatch } = this.props;
    let pageNumber = 1;
    let pageSize = 10;
    let filter = this.formFilterObject();
    dispatch(getReports(pageNumber, pageSize, filter));
  };

  handleExport = () => {
    //console.log("handleExport ----------------------- ");
    const { dispatch } = this.props;
    let filter = this.formFilterObject();
    dispatch(getExport(filter));
  };

  handleDemoNameValuesChange = (e) => {
    this.setState({ demoNameValues: e });
  };

  handleUserValuesChange = (e) => {
    this.setState({ userNameValues: e });
  };

  handleRegionValuesChange = (e) => {
    this.setState({ regionValues: e });
  };

  handleCompanyValuesChange = (e, value) => {
    this.setState({ companyNameValues: value });
  };
  handlePurposeValuesChange = (e) => {
    this.setState({ purposeValues: e });
  };


  fetchCompanies = (event, searchString) => {
    this.setState({ matchedCompanies: [], fetchingCompanies: true });
    fetchCompaniesBySearchString({ searchString })
      .then((result) => {
        result.length !== 0 ? this.setState({ matchedCompanies: result, fetchingCompanies: true }) : this.setState({ fetchingCompanies: false });
        this.setState({
          matchedCompanies: this.state.matchedCompanies.dataSet?.map(function (row) {
            return { title: row.company, id: row.id }
          })
        });
      });
  };

  render() {

    var startOfMonth = require('date-fns/start_of_month');
    var startOfYear = require('date-fns/start_of_year');
    var endOfMonth = require('date-fns/end_of_month');
    var endOfYear = require('date-fns/end_of_year');
    var startOfWeek = require('date-fns/start_of_week');
    var endOfWeek = require('date-fns/end_of_week');
    var startOfDay = require('date-fns/start_of_day');
    var endOfDay = require('date-fns/end_of_day');

    const {
      classes,
      demoNames,
      demoPurposes,
      userNames,
    } = this.props;
    const {
      demoNameValues,
      regionValues,
      userNameValues,
      companyNameValues,
    } = this.state;

    const { fetchingCompanies, matchedCompanies, value } = this.state;
    //afterToday() => boolean;

    const DateRangePickerCustomToolbar = (props) => (

      <div className="field">
        <DateRangePicker
          onChange={(value) => {
            //localStorage.timezone
            var startTimeStr1 = moment(value[0]).tz(localStorage.timezone, true).toISOString();
            var endTimeStr1 = moment(endOfDay(value[1])).tz(localStorage.timezone, true).toISOString();
            console.log("------------ startTimeStr with timezone - " + startTimeStr1);
            console.log("------------ endTimeStr with timezone  - " + endTimeStr1);
            this.setState({ dateValue: value, startDate: startTimeStr1, endDate: endTimeStr1 });
          }
          }
          value={this.state.dateValue}
          ranges={[
            {
              label: "Today",
              value: [startOfDay(new Date()), endOfDay(new Date())],
            },
            {
              label: "Yesterday",
              value: [
                startOfDay(dateFns.addDays(new Date(), -1)),
                endOfDay(new Date()),
              ],
            },
            {
              label: "This Week",
              // value: [dateFns.subDays(new Date(), 6), new Date()],
              value: [
                startOfDay(startOfWeek(new Date())),
                endOfDay(new Date())
              ]
            },
            {
              label: "Last Week",
              // value: [dateFns.subDays(new Date(), 6), new Date()],
              value: [
                startOfDay(startOfWeek(startOfWeek(new Date()) - 1)),
                endOfDay(startOfWeek(new Date()) - 1)
              ]
            },
            {
              label: 'This Month',
              value: [
                startOfDay(startOfMonth(new Date())),
                endOfDay(new Date())
              ]
            },
            {
              label: 'Last Month',
              value: [
                startOfDay(startOfMonth(startOfMonth(new Date()) - 1)),
                endOfDay(endOfMonth(startOfMonth(new Date()) - 1))
              ]
            },
            {
              label: 'This Year',
              value: [
                startOfDay(startOfYear(new Date())),
                endOfDay(new Date())
              ]
            },
            {
              label: 'Last Year',
              value: [
                startOfDay(startOfYear(startOfYear(new Date()) - 1)),
                endOfDay(endOfYear(startOfYear(new Date()) - 1))
              ]
            },
          ]}
        />
      </div>
    );

    let companies = []
    if (matchedCompanies?.length > 0) {
      companies = matchedCompanies
    }

    const demoNameOptions = [];
    if (
      !!demoNames &&
      Object.keys(demoNames).length > 0 &&
      demoNames.dataSet.length > 0
    ) {
      demoNames.dataSet.forEach((demo) => {
        let temp = {};
        temp["value"] = demo.title;
        temp["label"] = demo.title;
        demoNameOptions.push(temp);
      });
    }
    const userNameOptions = [];
    if (
      !!userNames &&
      Object.keys(userNames).length > 0 &&
      userNames.dataSet.length > 0
    ) {
      userNames.dataSet.forEach((user) => {
        let temp = {};
        temp["value"] = user.email;
        temp["label"] = user.firstName + " " + user.lastName;
        userNameOptions.push(temp);
      });
    }

    const regions = JSON.parse(localStorage.getItem("myRegions"));
    const regionOptions = [];
    if (!!regions && regions.length > 0) {
      regions.forEach((region) => {
        let temp = {};
        temp["value"] = region.name;
        temp["label"] = region.name;
        regionOptions.push(temp);
      });
    }

    const purposeNameOptions = [];
    if (demoPurposes.length > 0) {
      demoPurposes.forEach((purpObj) => {
        let temp = {};
        temp["value"] = purpObj.purpose;
        temp["label"] = purpObj.purpose;
        purposeNameOptions.push(temp);
      });
    }

    return (
      <div className={classes.root}>
        <div>
          <a href="/login" className="logout" title="Log Out" onClick={() => localStorage.setItem("aep-header", "")}>
            <i className="fas fa-sign-out-alt">&nbsp;&nbsp;&nbsp;&nbsp;</i>
          </a>
          <br />
          <h3>Schedule Report</h3>
        </div>

        <Grid style={{ marginTop: "1%" }} container spacing={2}>
          <Grid item xs={3}>
            <div className="form-group">
              <label className="input-label" htmlFor="demoNames">
                Demo Names
              </label>
              <Select className="select-box"
                value={demoNameValues}
                closeMenuOnSelect={false}
                onChange={this.handleDemoNameValuesChange}
                name="demoNames"
                isMulti
                options={demoNameOptions}
                classNamePrefix="Select..."
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className="form-group">
              <label className="input-label" htmlFor="companies">
                Companies
              </label>
              <Autocomplete
                value={companyNameValues || []}
                onChange={this.handleCompanyValuesChange}
                id="companies"
                options={companies}
                getOptionLabel={(option) => option.title}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
                size="small"
                onInputChange={this.fetchCompanies}
                noOptionsText="Please type to search"
                multiple
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <div className="form-group">
              <label className="input-label" htmlFor="region">
                Region
              </label>
              <Select
                value={regionValues}
                closeMenuOnSelect={false}
                onChange={this.handleRegionValuesChange}
                name="region"
                isMulti
                options={regionOptions}
                classNamePrefix="Select..."
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="form-group">
              <label className="input-label" htmlFor="users">
                Users
              </label>
              <Select
                value={userNameValues}
                onChange={this.handleUserValuesChange}
                closeMenuOnSelect={false}
                name="users"
                isMulti
                options={userNameOptions}
                classNamePrefix="Select..."
              />
            </div>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "1%" }} container spacing={2}>
          <Grid item xs={4}>
            <DateRangePickerCustomToolbar />
          </Grid>
          <Grid item xs={2}>
            <Button
              className="button-style"
              variant="contained"
              onClick={() => this.clearSearch()}
            >
              Clear Search
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              className="button-style"
              variant="contained"
              onClick={() => this.handleFilter()}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              className="button-style"
              variant="contained"
              onClick={() => this.handleExport()}
            >
              Export
            </Button>
          </Grid>
        </Grid>

        {/* {!!reportDetails && reportDetails.hasOwnProperty('dataSet') && reportDetails.dataSet.length > 0 && */}
        <Grid container>
          <Grid item xs={12}>
            <ReportsTable
              modifyDataAndDispatch={this.handleModifyDataAndDispatch}
            />
          </Grid>
        </Grid>

        {/* } */}
      </div>
    );
  }
}

ReportsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  demoNames: state.reports.demoNames,
  demoPurposes: state.reports.demoPurposes,
  regions: state.auth.regions,
  reportDetails: state.reports.reportDetails,
  userNames: state.reports.userNames,
  companyNames: state.reports.companyNames,
});

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(ReportsPage))
);
