import { loginAPI } from "../api/auth-api";

import {
  LOGIN_INIT,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
} from "../constants/auth-types";

export function login(username, password, aepHeader) {
  console.log("Inside action")
  return (dispatch) => {
    dispatch({ type: LOGIN_INIT });
    loginAPI(aepHeader, username, password)
      .then((response) => dispatch({ type: LOGIN_SUCCESS, response }))
      .catch((error) => dispatch({ type: LOGIN_FAILED, error }));
  };
}
